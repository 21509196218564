import React from 'react';
import LoginForm from './LoginForm.js'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom';


function Login() {
    function redirectToDashboard(){
        if(localStorage.getItem('saved')){
          return <Redirect to="/dashboard" />
        }
    }
    function isExpired(){
        if(localStorage.getItem('saved') && (new Date().getTime() - localStorage.getItem('saved') > 168 * 60 * 60 * 1000)){
            localStorage.clear()
        }
    }
    return (
        <>
        {redirectToDashboard()}
        {isExpired()}
            <Helmet>
                <title>SIAK - Login Page</title>
            </Helmet>
            <div className="bg-primary" id="layoutAuthentication">
                <div id="layoutAuthentication_content">
                    <main>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-5">
                                    <div className="card shadow-lg border-0 rounded-lg mt-5">
                                        <div className="card-header">
                                            <h3 className="text-center font-weight-light my-4">Login Page</h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="alert alert-danger" role="alert">
                                                Per Tanggal 30 Januari 2023, Web Aplikasi tidak di maintance dan akan ditutup.
                                                Terima kasih kepada pengguna setia dari tahun 2019 - 2023 :D
                                             </div>
                                            <LoginForm />
                                        </div>
                                        <div className="card-footer text-center">
                                            <div className="small">Cara baru melihat nilai secara Informatif<br></br>Made by <a href="https://www.linkedin.com/in/cahyamulyadi">Adi</a></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
}

export default Login;
