import { Tab, Tabs, Table } from "react-bootstrap";
import React, { Component } from "react";
import './Rank.css'


export default class Rank extends Component {
  constructor(props) {
    super();
    this.state = {
      // Takes active tab from props if it is defined there
      activeTab: props.activeTab || 1,
    };

    // Bind the handleSelect function already here (not in the render function)
    this.handleSelect = this.handleSelect.bind(this);
  }

  render() {
    const data = this.props;
    let count = 0;
    return (
        <Tabs fill activeKey={this.state.activeTab} onSelect={this.handleSelect}>
          {Object.keys(data.data).map((e, i) => {
            count++;
            return (
              <Tab eventKey={count} title={e}>
            <div class="scrollable">
            <Table responsive>
              <thead>
                <tr>
                  <th>Rank</th>
                  <th>Nama</th>
                  <th>Jurusan</th>
                  <th>Prodi</th>
                  <th>IP</th>
                </tr>
              </thead>
              <tbody>
                {data.data[e].map((x, i) => {
                  return (
                    <tr>
                      <td>{x[0]}</td>
                      <td>{x[1]}</td>
                      <td>{x[2]}</td>
                      <td>{x[3]}</td>
                      <td>{x[4]}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            </div>
          </Tab>
            );
          })}
        </Tabs>
      );
    }

  handleSelect(selectedTab) {
    // The active tab must be set into the state so that
    // the Tabs component knows about the change and re-renders.
    this.setState({
      activeTab: selectedTab,
    });
  }
}
