import React, { Component } from 'react'
import { Tab, Tabs } from "react-bootstrap";
import Chart from "chart.js";
import Rank from "./Rank";
let myLineChart;


//--Chart Style Options--//
Chart.defaults.global.defaultFontFamily = "'PT Sans', sans-serif"
Chart.defaults.global.legend.display = false;

//--Chart Style Options--//

export default class IPKChart extends Component {
    chartRef = React.createRef();
    chartRef2 = React.createRef();

    componentDidMount() {
        this.buildChart();
    }
    buildChart = () => {
        const { data, labels, avg_pnj, avg_jurusan, avg_prodi, avg_kelas } = this.props;
        const myChartRef = this.chartRef.current.getContext("2d");
        const myChartRef2 = this.chartRef2.current.getContext("2d");


        if (typeof myLineChart !== "undefined") myLineChart.destroy();

        myLineChart = new Chart(myChartRef2, {
            type: "line",
            data: {
                labels: labels,
                datasets: [{
                    label: "IP Semester",
                    lineTension: 0.3,
                    backgroundColor: "rgba(2,117,216,0.2)",
                    borderColor: "rgba(2,117,216,1)",
                    pointRadius: 5,
                    pointBackgroundColor: "rgba(2,117,216,1)",
                    pointBorderColor: "rgba(255,255,255,0.8)",
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: "rgba(2,117,216,1)",
                    pointHitRadius: 50,
                    pointBorderWidth: 2,
                    data: data,
                }],
            },
            options: {
                responsive: true,
                maintainAspectRatio: true,
                scales: {
                    xAxes: [{
                        time: {
                            unit: 'date'
                        },
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            maxTicksLimit: 7
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            max: 4,
                            maxTicksLimit: 5
                        },
                        gridLines: {
                            color: "rgba(0, 0, 0, .125)",
                        }
                    }],
                },
                legend: {
                    display: false
                }
            }

        });
        myLineChart = new Chart(myChartRef, {
            type: "bar",
            data: {
                labels: labels,
                datasets: [{
                    label: "IP Semester",
                    lineTension: 0.3,
                    backgroundColor: "blue",
                    borderColor: "blue",
                    pointRadius: 5,
                    pointBackgroundColor: "blue",
                    pointBorderColor: "rgba(255,255,255,0.8)",
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: "blue",
                    pointHitRadius: 50,
                    pointBorderWidth: 2,
                    data: data,
                }, {
                    label: "Avg PNJ",
                    lineTension: 0.3,
                    backgroundColor: "red",
                    borderColor: "red",
                    pointRadius: 5,
                    pointBackgroundColor: "red",
                    pointBorderColor: "rgba(255,255,255,0.8)",
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: "red",
                    pointHitRadius: 50,
                    pointBorderWidth: 2,
                    data: avg_pnj,
                }, {
                    label: "Avg Jurusan",
                    lineTension: 0.3,
                    backgroundColor: "green",
                    borderColor: "green",
                    pointRadius: 5,
                    pointBackgroundColor: "green",
                    pointBorderColor: "rgba(255,255,255,0.8)",
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: "green",
                    pointHitRadius: 50,
                    pointBorderWidth: 2,
                    data: avg_jurusan,
                }, {
                    label: "Avg Prodi",
                    lineTension: 0.3,
                    backgroundColor: "yellow",
                    borderColor: "yellow",
                    pointRadius: 5,
                    pointBackgroundColor: "rgba(2,117,216,1)",
                    pointBorderColor: "yellow",
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: "yellow",
                    pointHitRadius: 50,
                    pointBorderWidth: 2,
                    data: avg_prodi,
                }, {
                    label: "Avg Kelas",
                    lineTension: 0.3,
                    backgroundColor: "violet",
                    borderColor: "violet",
                    pointRadius: 5,
                    pointBackgroundColor: "rgba(2,117,216,1)",
                    pointBorderColor: "violet",
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: "violet",
                    pointHitRadius: 50,
                    pointBorderWidth: 2,
                    data: avg_kelas,
                }],
            },
            options: {
                responsive: true,
                scales: {
                    xAxes: [{
                        time: {
                            unit: 'date'
                        },
                        gridLines: {
                            display: false
                        },
                        ticks: {
                            maxTicksLimit: 7
                        }
                    }],
                    yAxes: [{
                        ticks: {
                            max: 4,
                            maxTicksLimit: 5
                        },
                        gridLines: {
                            color: "rgba(0, 0, 0, .125)",
                        }
                    }],
                },
                legend: {
                    display: true
                }
            }

        });
    }

    render() {

        return (
            <Tabs fill defaultActiveKey="line-chart" id="statistik-tab">
                <Tab eventKey="line-chart" title="IP Semester">
                    <canvas id="myLineChart" ref={this.chartRef2} />
                </Tab>
                <Tab eventKey="bar-chart" title="IP Rata-rata">
                    <canvas id="myChart" ref={this.chartRef} />
                </Tab>
                <Tab eventKey="rank-pnj" title="Top 10 - PNJ">
                    <Rank data={this.props.rank_pnj} />
                </Tab>
                <Tab eventKey="rank-jurusan" title="Top 10 - Jurusan">
                    <Rank data={this.props.rank_jurusan} />
                </Tab>
                <Tab eventKey="rank-prodi" title="Top 5 - Prodi">
                    <Rank data={this.props.rank_prodi} />
                </Tab>
                <Tab eventKey="rank-kelas" title="Top 5 - Kelas">
                    <Rank data={this.props.rank_kelas} />
                </Tab>
            </Tabs>
        )
    }
}