import React, { Component } from "react";
import { Alert, Card } from "react-bootstrap";

export default class Kompen extends Component {
  render() {
    return (
    <>
      <div class="card-body">
        <Card.Header as="h5">KOMPEN</Card.Header>
        <br></br>
        <p class="card-text">
          <Alert variant="success">IZIN : {this.props.kompen[0]}</Alert>
          <Alert variant="primary">SAKIT : {this.props.kompen[1]}</Alert>
          <Alert variant="danger">ALPHA : {this.props.kompen[2]}</Alert>
          <Alert variant="warning">TERLAMBAT : {this.props.kompen[3]}</Alert>
        </p>
      </div>
      <div class="card-footer">
        <center><large class="text-primary">Last updated: {this.props.time}</large></center>
      </div>
    </>
    );
  }
}
