import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Loader from 'react-loader-spinner';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';
import * as SecureLS from 'secure-ls';
import ReCAPTCHA from "react-google-recaptcha";


export default function LoginForm() {
  const ls = new SecureLS({ encodingType: 'aes', encryptionSecret: '$R#]wqJf6Cm;gxP}' });
  const [status, setStatus] = useState(false);
  const [forgetPass, setForgetPass] = useState(false);
  const [waitLogin, setWaitLogin] = useState(false);
  let history = useHistory();
  const { register, handleSubmit, errors } = useForm();
  const {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2
  } = useForm();

  const onSubmit = (data, value) => {
    document.body.click()
    setWaitLogin(true)
    //https://secure-temple-82386.herokuapp.com/ heroku alternative
    //aws https://h6sindrqi6.execute-api.ap-southeast-1.amazonaws.com/dev

    axios.post('https://hif0te2pvh.execute-api.ap-southeast-1.amazonaws.com/login', {
      nim: data['nim'],
      password: data['password'],
      token: value
    })
      .then((response) => {
        if (response.data.status) {
          localStorage.setItem('saved', new Date().getTime());
          localStorage.setItem('siak_version', response.data.version);
          ls.set("siak_token", response.data.siak_token);
          ls.set("data", response.data);
          localStorage.setItem("nim", data['nim']);
          ls.set("password", data['password']);
          setWaitLogin(false);
          history.push('/dashboard')
        } else {
          setWaitLogin(false);
          setStatus(true);
        }
      }, (error) => {
        console.log(error);
        setWaitLogin(false);
      });
  };
  const onSubmit2 = (data, value) => {
    setForgetPass(false);
    ls.remove('password');
    document.body.click()
    //https://secure-temple-82386.herokuapp.com/ heroku alternative
    //aws https://h6sindrqi6.execute-api.ap-southeast-1.amazonaws.com/dev

    axios.post('https://hif0te2pvh.execute-api.ap-southeast-1.amazonaws.com/forgetpassword', {
      nama: data['forget_nama'],
      nim: data['forget_nim'],
      tgl_lahir : data['forget_tgllahir'],
      token: value
    })
      .then((response) => {
        if (response.data.status) {
          ls.set("password", response.data.data);
          setForgetPass(true);
        } else {
          setForgetPass(false);
        }
      }, (error) => {
        console.log(error);
        setForgetPass(false);
      });
  };
  const popoverPassword = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Lupa password</Popover.Title>
      <Popover.Content>
        <div className="form-group">
          <label className="small mb-1" htmlFor="inputNIM">NIM</label>
          <input className="form-control py-4" id="inputNIM" type="text" name="forget_nim" placeholder="Masukan NIM" ref={register2({
            required: "NIM tidak boleh kosong",
            pattern: {
              value: /^[0-9]{7,15}$/i,
              message: "NIM tidak valid",
            },
          })} />
          <br></br>
          {errors2.forget_nim && <div className="alert alert-danger" role="alert">
            {errors2.forget_nim.message}
          </div>}
        </div>
        <div className="form-group">
          <label className="small mb-1" htmlFor="inputNama">Nama</label>
          <input className="form-control py-4" id="inputNama" type="text" name="forget_nama" placeholder="Masukan nama" ref={register2({
            required: "Nama tidak boleh kosong",
          })} />
          <br></br>
          {errors2.forget_nama && <div className="alert alert-danger" role="alert">
            {errors2.forget_nama.message}
          </div>}
        </div>
        <div className="form-group">
          <label className="small mb-1" htmlFor="inputTanggalLahir">Tanggal Lahir</label>
          <input className="form-control py-4" id="inputTnaggalLahir" type="date" placeholder="Masukan tanggal lahir" name="forget_tgllahir" ref={register2({
            required: "Tanggal lahir tidak boleh kosong",
          })} />
          <br></br>
          {errors2.forget_tgllahir && <div className="alert alert-danger" role="alert">
            {errors2.forget_tgllahir.message}
          </div>}
        </div>
        <ReCAPTCHA
          sitekey="6LfFf9kZAAAAAFPAgw9YvbMSLcL8jsqlNJ25T62I"
          onChange={handleSubmit2(onSubmit2)}
          size="compact"
        />
        <br></br>
        <br></br>
        
      </Popover.Content>
    </Popover>
  );
  const popover = (
    <Popover id="popover-basic">
      <Popover.Title as="h3">Verify you are a human</Popover.Title>
      <Popover.Content>
        <ReCAPTCHA
          sitekey="6LfFf9kZAAAAAFPAgw9YvbMSLcL8jsqlNJ25T62I"
          onChange={handleSubmit(onSubmit)}
          size="compact"
        />
      </Popover.Content>
    </Popover>
  );
  return (
    <div id="loginForm">
      {waitLogin &&
        <div
          style={{
            width: "100%",
            height: "100",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Loader type="ThreeDots" color="#2BAD60" height="100" width="100" />
        </div>
      }
      {status &&
        <div className="alert alert-danger" role="alert">GAGAL LOGIN - NIM ATAU PASSWORD SALAH / SERVER PNJ DOWN</div>
      }
      {forgetPass &&
        <div class="alert alert-success" role="alert">Password anda adalah : {ls.get('password')}</div>
      }
      <div className="form-group">
        <label className="small mb-1" htmlFor="inputNIM">NIM</label>
        <input className="form-control py-4" id="inputNIM" type="text" name="nim" disabled placeholder="Enter your NIM" ref={register({
          required: "NIM tidak boleh kosong",
          pattern: {
            value: /^[0-9]{7,15}$/i,
            message: "NIM tidak valid",
          },
        })} />
        <br></br>
        {errors.nim && <div className="alert alert-danger" role="alert">
          {errors.nim.message}
        </div>}
      </div>
      <div className="form-group">
        <label className="small mb-1" htmlFor="inputPassword">Password</label>
        <input className="form-control py-4" id="inputPassword" type="password" name="password" disabled placeholder="Enter password" ref={register({
          required: "Password tidak boleh kosong",
        })} />
        <br></br>
        {errors.password && <div className="alert alert-danger" role="alert">
          {errors.password.message}
        </div>}
      </div>
      <div className="form-group d-flex align-items-center justify-content-between mt-4 mb-0">
        <OverlayTrigger trigger="click" rootClose={true} placement="auto" overlay={popoverPassword}>
          <b className="small">Lupa password?</b>
        </OverlayTrigger>
        <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={popover}>
          <Button variant="primary" disabled={true}>{waitLogin ? 'Loading…' : 'Login'}</Button>
        </OverlayTrigger>
      </div>
    </div>
  );
}
