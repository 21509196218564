import React, { Component } from "react";
import { Alert, Card } from "react-bootstrap";

export default class Biodata extends Component {
  render() {
    return (
    <>
      <div class="card-body">
        <Card.Header as="h5">BIODATA</Card.Header>
        <br></br>
        <p class="card-text">
          <Alert variant="info">Nama : {this.props.biodata[0]}</Alert>
          <Alert variant="info">NIM : {this.props.biodata[4]}</Alert>
          <Alert variant="info">STATUS : {this.props.biodata[3]}</Alert>
          <Alert variant="info">JURUSAN : {this.props.biodata[5]}</Alert>
        </p>
      </div>
      <div class="card-footer">
        <center><large class="text-primary">Last updated: {this.props.time}</large></center>
      </div>
    </>
    );
  }
}
