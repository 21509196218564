import React from "react";
import { Button, Alert } from "react-bootstrap";

export default function AlertIPK(props) {
  const [show, setShow] = React.useState(true);
  const name = props.name;
  const ipk = props.ipk;
  if (show) {
    return (
      <Alert variant="success" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>Well done!</Alert.Heading>
        <p>
          Aww yeah, you successfully read this important alert message.
          <hr/>
          Dear {name}, Just wanna let you know, you're doing great. From now on everything
          will be okay. Let's fight for another next semester!
        </p>
        <hr />
        <p className="mb-0">
          Kamu sudah menyelesaikan <b>{ipk[1]}</b> SKS sejauh ini, dan sudah
          mendapatkan total nilai <b>{ipk[0]}</b>. Hasil IPK mu adalah <b>{ipk[2]}</b>
        </p>
      </Alert>
    );
  }
  return <Button onClick={() => setShow(true)}>Show IPK</Button>;
}
