import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Button, Navbar, Nav, Alert, Popover, OverlayTrigger, Tooltip } from "react-bootstrap";
import AlertIPK from "./AlertIPK";
import ChartIPK from "./IPKChart";
import Biodata from "./Biodata";
import Kompen from "./Kompen";
import TableNilai from "./TableNilai";
import { faSignOutAlt, faSync, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Redirect } from "react-router-dom";
import * as SecureLS from 'secure-ls';
import ReCAPTCHA from "react-google-recaptcha";
import 'react-chatbox-component/dist/style.css';
import { ChatBox } from 'react-chatbox-component';
import Pusher from 'pusher-js';
const ls = new SecureLS({ encodingType: 'aes', encryptionSecret: '$R#]wqJf6Cm;gxP}' });


export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
      isUpdateFail: false,
      waitUpdate: false,
      data: ls.get("data"),
      password: ls.get("password"),
      nim: localStorage.getItem("nim"),
      chat_log: ls.get("chat_log"),
      siak_token: ls.get("siak_token"),
      chat_status: true
    }

    if (localStorage.getItem('saved') === null || (localStorage.getItem('saved') && (new Date().getTime() - localStorage.getItem('saved') > 120 * 60 * 60 * 1000))) {
      localStorage.clear();
      ls.clear();
      this.props.history.push("/")
      return;
    }
    if (ls.get("siak_token") === null) {
      localStorage.clear();
      ls.clear();
      this.props.history.push("/")
      return;
    }
    if(localStorage.getItem('siak_version') !== "1.0"){
      localStorage.clear();
      ls.clear();
      this.props.history.push("/")
      return;
    }
   
  }
  componentDidMount() {
    
    //window.scrollTo(0, 0)

    //axios.post('https://586wpznbgb.execute-api.ap-southeast-1.amazonaws.com/dev', {
    //  nim: this.state.nim,
    //  chat_log_data: "true",
    //  siak_token: this.state.siak_token
    //}).then((response) => {
    //  if (response.data.status) {
    //    this.setState({ chat_log: response.data.chat_log })
    //    console.log("state update")
    //  } else {
    //    console.log("server mati gan")
    //  }
    //})
    const isJSON = (param) => {
      try {
        JSON.parse(param);
        return true;
      } catch (error) {
        return false;
      }
    }
    const updateMsg = (data) => {
      (isJSON(data)) ? this.setState(prevState => ({ chat_log: [...this.state.chat_log, JSON.parse(data)] }))  : this.setState(prevState => ({ chat_log: [...this.state.chat_log, data] }))
      let y = window.pageYOffset;
      document.getElementById('end-of-chat').scrollIntoView()
      window.scrollTo(0, y);

    }
    const pusher = new Pusher('39a248c960de6103d616', {
      cluster: 'ap1',
      forceTLS: true,
      authorizer: (channel, options) => {
        return {
          authorize: (socketId, callback) => {
            axios.post('https://hif0te2pvh.execute-api.ap-southeast-1.amazonaws.com/chat', {
              socket_id: socketId, channel_name: channel.name, nim: this.state.nim, siak_token: this.state.siak_token, pusher: "yes"
            }).then((response) => {
              if (response.data.status) {
                this.setState({ chat_log: response.data.chat_log })
                console.log("chat retrieve")
                var messageBody = document.querySelector('#main > div > div.chat-box > div.msg-page');
                messageBody.scrollTop = messageBody.scrollHeight - messageBody.clientHeight;
                callback(false, JSON.parse(response.data.pusher));
              } else {
                console.log("server mati")
              }
              
            })
          }
        }
      }
    });
   
    var channel = pusher.subscribe('private-siak-channel');
    channel.bind('update-message', function (data) {
      updateMsg(data);
    });
  }
  
  render() {
    const scrollChatbox = (y) => {
      document.getElementById('end-of-chat').scrollIntoView()
      window.scrollTo(0, y);
    }

    const sendMessage = (message) => {
      if (this.state.chat_status) {
        axios.post('https://hif0te2pvh.execute-api.ap-southeast-1.amazonaws.com/chat', { // ini link api buat chat dan history log
          nim: this.state.nim,
          siak_token: this.state.siak_token,
          message: message
        }).then((response) => {
          if (response.data.status) {
            this.setState({chat_status: false})
            console.log("message send")
            scrollChatbox(window.pageYOffset)
            setTimeout(() => {
              this.setState({chat_status: true}) 
            }, 10000);
          } else {
            console.log("message gagal kirim")
          }
        })
      } else {
        alert("slow down, you send message too fast")
      }
    }


    const user = {
      "uid": this.state.data.biodata[4]
    }

    const renderTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        Data ini di olah dengan <del>kecerdasan buatan</del> database, karena data yang di himpun <b>hanya data dari pengguna Web-app SIAK ini</b>. (bukan data keseluruhan mahasiswa PNJ)
        <br></br><br></br>Setiap saat data statistik ini akan berubah terus menerus, sejalan dengan banyaknya pengguna.
        <br></br><br></br>
        Data ranking di filter dengan syarat SKS lebih dari 15 dan di urut berdasarkan IP, SKS, dan Total nilai.
      </Tooltip>
    );
    function returnDataChart(data) {
      var returned_data = [];
      Object.keys(data).forEach(key => {
        returned_data.push(data[key][0])
      })
      return returned_data
    }
    var ip_semester = [];
    Object.keys(this.state.data.ip_mahasiswa).forEach(key => {
      ip_semester.push(this.state.data.ip_mahasiswa[key][2])
    });
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }

    const logOut = () => {
      localStorage.clear();
      ls.clear();
      this.setState({ redirect: "/" });
    };
    const updateData = (value) => {
      document.body.click()
      this.setState({ waitUpdate: true })
      axios.post('https://hif0te2pvh.execute-api.ap-southeast-1.amazonaws.com/login', { // ini link API buat retrieve data biasa
        nim: this.state.nim,
        password: this.state.password,
        token: value
      })
        .then((response) => {
          if (response.data.status) {
            localStorage.setItem('saved', new Date().getTime());
            ls.set("data", response.data);
            this.setState({ data: response.data, waitUpdate: false })
          } else {
            this.setState({ isUpdateFail: true, waitUpdate: false });
          }
        }, (error) => {
          this.setState({ waitUpdate: false, isUpdateFail: true })
          console.log(error);
        });
    };

    const popover = (
      <Popover id="popover-basic">
        <Popover.Title as="h3">Verify you are a human</Popover.Title>
        <Popover.Content>
          <ReCAPTCHA
            sitekey="6LfFf9kZAAAAAFPAgw9YvbMSLcL8jsqlNJ25T62I"
            onChange={updateData}
            size="compact"
          />
        </Popover.Content>
      </Popover>
    );

    return (
      <>
        <Helmet>
          <title>SIAK - Dashboard</title>
          <link href="../css/styles.css" rel="stylesheet" />
          <script
            src="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/js/all.min.js"
            crossorigin="anonymous"
          ></script>
        </Helmet>
        <body class="sb-nav-fixed">
          <Navbar
            collapseOnSelect
            expand="lg"
            bg="dark"
            variant="dark"
            sticky="top"
          >
            <Navbar.Brand>SIAK-PNJ</Navbar.Brand>
            <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={popover}>
              <Button disabled={this.state.waitUpdate} variant="primary" size="md">
                <FontAwesomeIcon icon={faSync} /> {this.state.waitUpdate ? 'Updating..' : 'Update'}
              </Button>
            </OverlayTrigger>
            {"\u00A0"}
            <Button onClick={logOut} disabled={this.state.waitUpdate} variant="warning" size="md">
              <FontAwesomeIcon icon={faSignOutAlt} /> Logout
            </Button>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link
                  as="a"
                  href="https://old.pnj.ac.id/mahasiswa/edom.html"
                  target="_blank"
                >
                  EDOM
                </Nav.Link>
                <Nav.Link
                  as="a"
                  href="https://pnj.ac.id/kuesioner"
                  target="_blank"
                >
                  Survei Layanan
                </Nav.Link>
                <Nav.Link
                  as="a"
                  href="https://akademik.pnj.ac.id/news/index/1801/Standar-Pelayanan-Publik.html"
                  target="_blank"
                >
                  Standard Pelayanan
                </Nav.Link>
                <Nav.Link
                  as="a"
                  href="http://surat.akademik.pnj.ac.id/"
                  target="_blank"
                >
                  Pengajuan Surat Keterangan
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <section id="main">
            <div class="container-fluid">
              <br></br>
              {this.state.isUpdateFail &&
                <Alert.Heading variant="warning"> UPDATE DATA GAGAL - Something went wrong </Alert.Heading>}
              <div class="card-deck">
                <div class="card">
                  <Biodata biodata={this.state.data.biodata} time={this.state.data.time} />
                </div>
                <div class="card">
                  <Kompen kompen={this.state.data.kompen} time={this.state.data.time} />
                </div>
              </div>
              <br></br>
              <ChatBox messages={this.state.chat_log} user={user} onSubmit={sendMessage} />
              <br></br>
              <AlertIPK ipk={this.state.data.ipk} name={this.state.data.biodata[0]} />
              <br></br>
              <div class="row">
                <div class="col-xl-12">
                  <div class="card mb-4">
                    <div class="card-header">
                      <i class="fas fa-chart-area mr-1"></i>
                      Data Statistik <OverlayTrigger
                        placement="right"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                      ><FontAwesomeIcon icon={faInfoCircle} color="lime" /></OverlayTrigger> | <Alert variant="primary" style={{display:'inline-block'}}>Last Update: {this.state.data.time}</Alert>
                    </div>
                    <ChartIPK data={ip_semester} labels={Object.keys(this.state.data.ip_mahasiswa)} avg_pnj={returnDataChart(this.state.data.avg.pnj)} avg_jurusan={returnDataChart(this.state.data.avg.jurusan)} avg_prodi={returnDataChart(this.state.data.avg.prodi)} avg_kelas={returnDataChart(this.state.data.avg.kelas)} rank_pnj={this.state.data.rank.pnj} rank_jurusan={this.state.data.rank.jurusan} rank_prodi={this.state.data.rank.prodi} rank_kelas={this.state.data.rank.kelas} />
                    <hr />
                  </div>
                </div>
              </div>
              <div class="card-header">
                <i class="fas fa-table mr-1"></i>
                Tabel Nilai Mahasiswa
              </div>
              <div class="card-body">
                <TableNilai data={this.state.data.nilai} />
              </div>
            </div>
          </section>
          <footer class="py-4 bg-light mt-auto">
            <div class="container-fluid">
              <div class="d-flex align-items-center justify-content-between small">
                <div class="text-muted">
                  Copyright &copy; Custom SIAK PNJ by{" "}
                  <a
                    href="https://www.linkedin.com/in/cahyamulyadi/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Adi
                  </a>
                  . Themes by SB Admin
                </div>
                <div>
                  <a href="/dashboard">Privacy Policy</a>
                  &middot;
                  <a href="/dashboard">Terms &amp; Conditions</a>
                </div>
              </div>
            </div>
          </footer>
        </body>
      </>
    );
  }
}
